import { NgModule } from '@angular/core';
import { UniIconComponent } from './uni-icon.component';
import { MatIconModule } from '@angular/material/icon';
import { AppShellNoRenderModule } from '../../directives/app-shell-no-render/app-shell-no-render.module';

@NgModule({
  declarations: [UniIconComponent],
  imports: [MatIconModule, AppShellNoRenderModule],
  exports: [UniIconComponent],
})
export class UniIconsModule {}
