import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import {
  DEFAULT_LANGUAGE,
  LanguageService,
  supportedLanguages,
} from '../../services/language/language.service';

@Component({
  template: '<router-outlet></router-outlet>',
})
export class LanguageComponent implements OnInit {
  constructor(
    private activatedRoute: ActivatedRoute,
    private languageService: LanguageService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params: Params) => {
      if (!params['languageCode']) {
        return;
      }
      if (!supportedLanguages.includes(params['languageCode'])) {
        this.router
          .navigate([DEFAULT_LANGUAGE, '404'], {
            skipLocationChange: true,
            queryParamsHandling: 'merge',
          })
          .then();
        return;
      }
      this.languageService.changeLanguage(params['languageCode']).then();
    });
  }
}
