import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { LanguageService } from '../language/language.service';
import { I18NextPipe } from 'angular-i18next';
import { NavigationEnd } from '@angular/router';
import { environment } from '../../../environments/environment';
import { getBreadCrumbLabelByUrlPart } from '../../helpers/getBreadCrumbLabelByUrlPart';

export type BreadcrumbItem = { label: string; url?: string };

@Injectable({
  providedIn: 'root',
})
export class JsonLDService {
  static jsonLDScriptClass = 'structured-data-script';

  constructor(
    @Inject(DOCUMENT) private _document: Document,
    private languageService: LanguageService,
    private i18nextPipe: I18NextPipe,
  ) {}

  resolveNavigationEnd(navigationEnd: NavigationEnd): void {
    this.removeStructuredData();
    this.createBreadcrumbs(navigationEnd);
  }

  removeStructuredData(): void {
    Array.from(
      this._document.head.getElementsByClassName(
        JsonLDService.jsonLDScriptClass,
      ),
    ).forEach((el) => this._document.head.removeChild(el));
  }

  insertSchema(className: string, schema: Record<string, any>): void {
    const prefixedClassName = 'structured-data-' + className;
    let script: HTMLScriptElement;
    let shouldAppend = false;
    if (this._document.head.getElementsByClassName(prefixedClassName).length) {
      script = this._document.head.getElementsByClassName(
        prefixedClassName,
      )[0] as HTMLScriptElement;
    } else {
      script = this._document.createElement('script');
      script.type = 'application/ld+json';
      script.classList.add(JsonLDService.jsonLDScriptClass);
      script.classList.add(prefixedClassName);
      shouldAppend = true;
    }
    script.text = JSON.stringify(schema);
    if (shouldAppend) {
      this._document.head.appendChild(script);
    }
  }

  /**
   * Should only be called on home page
   */
  insertWebsiteSchema(): void {
    this.insertSchema('website', {
      '@context': 'https://schema.org',
      '@type': 'WebSite',
      url: 'https://lebenslauf.ch/',
      name: this.i18nextPipe.transform('cvGenerator'),
    });
  }

  insertBreadcrumbSchema(levels: BreadcrumbItem[]): void {
    const breadcrumbSchema: any = {
      '@context': 'https://schema.org',
      '@type': 'BreadcrumbList',
      itemListElement: levels.map((level, index) => {
        return {
          '@type': 'ListItem',
          position: index + 1,
          name: level.label,
          item: level.url,
        };
      }),
    };
    this.insertSchema('breadcrumb', breadcrumbSchema);
  }

  createBreadcrumbs(navigationEnd: NavigationEnd): void {
    let url = navigationEnd.url;
    // removes initial slash character
    url = url.slice(1);
    // remove get params for now.
    // Needs to change when job categories get indexed
    const getParamStartIndex = url.indexOf('?');
    if (getParamStartIndex !== -1) {
      url = url.slice(0, getParamStartIndex);
    }
    const urlParts = url.split('/');
    const allUrlParts = [...urlParts];
    // remove language
    urlParts.shift();
    if (urlParts.length === 0) {
      return;
    }
    const breadcrumbItems = urlParts.map((urlPart, index) => {
      let label = this.i18nextPipe.transform(
        getBreadCrumbLabelByUrlPart(urlPart),
      );
      label = label[0].toUpperCase() + label.slice(1);
      return {
        // allUrlParts is missing the first item from urlParts so we get index + 2
        url:
          environment.hostUrl + '/' + allUrlParts.slice(0, index + 2).join('/'),
        label: label,
      };
    });
    this.insertBreadcrumbSchema(breadcrumbItems);
  }

  addFaqSchema(faqEntries: { question: string; answer: string }[]): void {
    this.insertSchema('faq', {
      '@context': 'https://schema.org',
      '@type': 'FAQPage',
      mainEntity: faqEntries.map((faqEntry) => {
        return {
          '@type': 'Question',
          name: this.i18nextPipe.transform(faqEntry.question),
          acceptedAnswer: {
            '@type': 'Answer',
            text: `<p>${this.i18nextPipe.transform(faqEntry.answer)}</p>`,
          },
        };
      }),
    });
  }
}
