import { NgModule } from '@angular/core';
import { CookieHintComponent } from './cookie-hint.component';
import { SharedDefaultModule } from '../shared-default/shared-default.module';

@NgModule({
  declarations: [CookieHintComponent],
  exports: [CookieHintComponent],
  imports: [SharedDefaultModule],
})
export class CookieHintModule {}
