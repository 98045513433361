import { environment } from '../../environments/environment';

const keepParams: string[] = [];

export function getCanonicalUrl(url: string): string {
  const urlObject = new URL(environment.hostUrl + url);
  if (urlObject.pathname === '/de' || urlObject.pathname === '/') {
    return '';
  }
  urlObject.searchParams.forEach((value, key) => {
    if (keepParams.indexOf(key) === -1) {
      urlObject.searchParams.delete(key);
    }
  });
  return urlObject.pathname + urlObject.search;
}
