const urlPartToTranslationMapping: Record<string, string> = {
  'cv-generator': 'cvGenerator',
};

export function getBreadCrumbLabelByUrlPart(urlPart: string): string {
  const mappedTranslation = urlPartToTranslationMapping[urlPart];
  if (mappedTranslation) {
    return mappedTranslation;
  } else {
    return urlPart;
  }
}
