import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { AddSpacesToPhonePipe } from '../../pipes/addSpacesToPhone.pipe';
import { ValidPhoneLink } from '../../pipes/validPhoneLink';
import { FirstUpperCasePipe } from '../../pipes/firstUpperCase.pipe';
import { I18NextModule } from 'angular-i18next';
import { UniIconsModule } from '../uni-icon/uni-icons.module';
import { AppShellNoRenderModule } from '../../directives/app-shell-no-render/app-shell-no-render.module';
import { AppShellNoRenderDirective } from '../../directives/app-shell-no-render/app-shell-no-render.directive';
import { ModalComponent } from '../modal/modal.component';
import { UnescapeHtmlPipe } from '../../pipes/unescape-html.pipe';
import { MonthYearPipe } from '../../pipes/month-year.pipe';
import { CustomAdapterPipe } from '../../pipes/custom-adapter.pipe';
import { TrimPipe } from '../../pipes/trim/trim.pipe';

@NgModule({
  declarations: [
    AddSpacesToPhonePipe,
    ValidPhoneLink,
    FirstUpperCasePipe,
    ModalComponent,
    UnescapeHtmlPipe,
    MonthYearPipe,
    CustomAdapterPipe,
    TrimPipe,
  ],
  imports: [
    CommonModule,
    RouterModule,
    I18NextModule,
    UniIconsModule,
    AppShellNoRenderModule,
  ],
  exports: [
    CommonModule,
    RouterModule,
    AppShellNoRenderDirective,
    AddSpacesToPhonePipe,
    ValidPhoneLink,
    FirstUpperCasePipe,
    I18NextModule,
    UniIconsModule,
    ModalComponent,
    MonthYearPipe,
    UnescapeHtmlPipe,
    CustomAdapterPipe,
    TrimPipe,
  ],
})
export class SharedDefaultModule {}
