import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class SeoService {
  constructor(
    @Inject(DOCUMENT) private document: any,
    private title: Title,
    private metaService: Meta,
  ) {}

  setTitle(title: string): void {
    this.title.setTitle(title + ' | universaljob');
  }

  setMetaTag(name: string, value: string): void {
    if (this.metaService.getTag('name=' + name)) {
      this.metaService.updateTag({ name, content: value });
    } else {
      this.metaService.addTag({ name, content: value });
    }
  }

  setNoIndex(): void {
    this.setMetaTag('robots', 'noindex');
  }

  setIndex(): void {
    this.setMetaTag('robots', 'all');
  }

  setMetaDescription(description: string, autoLimit = false): void {
    if (!description) {
      return;
    }
    if (autoLimit) {
      description = description.slice(0, 155);
    }
    this.setMetaTag('description', description);
  }

  updateCanonicalUrl(url: string): void {
    const head = this.document.getElementsByTagName('head')[0];
    let element: HTMLLinkElement =
      this.document.querySelector(`link[rel='canonical']`) || null;
    if (element == null) {
      element = this.document.createElement('link') as HTMLLinkElement;
      head.appendChild(element);
    }
    element.setAttribute('rel', 'canonical');

    element.setAttribute('href', url);
  }
}
