import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

// prettier-ignore
export function registerIcons( matIconRegistry: MatIconRegistry, domSanitizer: DomSanitizer ) {
  matIconRegistry.addSvgIcon( 'arrow-up', domSanitizer.bypassSecurityTrustResourceUrl( '/assets/icons/arrow-up.svg' ) );
  matIconRegistry.addSvgIcon( 'arrow-right', domSanitizer.bypassSecurityTrustResourceUrl( '/assets/icons/arrow-right.svg' ) );
  matIconRegistry.addSvgIcon( 'arrow-down', domSanitizer.bypassSecurityTrustResourceUrl( '/assets/icons/arrow-down.svg' ) );
  matIconRegistry.addSvgIcon( 'arrow-left', domSanitizer.bypassSecurityTrustResourceUrl( '/assets/icons/arrow-left.svg' ) );
  matIconRegistry.addSvgIcon( 'trash', domSanitizer.bypassSecurityTrustResourceUrl( '/assets/icons/trash.svg' ) );
  matIconRegistry.addSvgIcon( 'documents-files-copy', domSanitizer.bypassSecurityTrustResourceUrl( '/assets/icons/documents-files-copy.svg' ) );
  matIconRegistry.addSvgIcon( 'chevron-down', domSanitizer.bypassSecurityTrustResourceUrl( '/assets/icons/chevron-down.svg' ) );
  matIconRegistry.addSvgIcon( 'linkedin', domSanitizer.bypassSecurityTrustResourceUrl( '/assets/icons/linkedin.svg' ) );
  matIconRegistry.addSvgIcon( 'facebook', domSanitizer.bypassSecurityTrustResourceUrl( '/assets/icons/facebook.svg' ) );
  matIconRegistry.addSvgIcon( 'mail', domSanitizer.bypassSecurityTrustResourceUrl( '/assets/icons/mail.svg' ) );
  matIconRegistry.addSvgIcon( 'menu', domSanitizer.bypassSecurityTrustResourceUrl( '/assets/icons/menu.svg' ) );
  matIconRegistry.addSvgIcon( 'check', domSanitizer.bypassSecurityTrustResourceUrl( '/assets/icons/check.svg' ) );
  matIconRegistry.addSvgIcon( 'x-circle', domSanitizer.bypassSecurityTrustResourceUrl( '/assets/icons/x-circle.svg' ) );
  matIconRegistry.addSvgIcon( 'color-palette', domSanitizer.bypassSecurityTrustResourceUrl( '/assets/icons/color-palette.svg' ) );
  matIconRegistry.addSvgIcon( 'eye', domSanitizer.bypassSecurityTrustResourceUrl( '/assets/icons/eye.svg' ) );
  matIconRegistry.addSvgIcon( 'file', domSanitizer.bypassSecurityTrustResourceUrl( '/assets/icons/file.svg' ) );
  matIconRegistry.addSvgIcon( 'file-text', domSanitizer.bypassSecurityTrustResourceUrl( '/assets/icons/file-text.svg' ) );
  matIconRegistry.addSvgIcon( 'plus', domSanitizer.bypassSecurityTrustResourceUrl( '/assets/icons/plus.svg' ) );
  matIconRegistry.addSvgIcon( 'all-caps', domSanitizer.bypassSecurityTrustResourceUrl( '/assets/icons/all-caps.svg' ) );
  matIconRegistry.addSvgIcon( 'drag-handle', domSanitizer.bypassSecurityTrustResourceUrl( '/assets/icons/drag-handle.svg' ) );
  matIconRegistry.addSvgIcon( 'color-palette_1', domSanitizer.bypassSecurityTrustResourceUrl( '/assets/icons/color-palette_1.svg' ) );
  matIconRegistry.addSvgIcon( 'justify-left', domSanitizer.bypassSecurityTrustResourceUrl( '/assets/icons/justify-left.svg' ) );
  matIconRegistry.addSvgIcon( 'text-size', domSanitizer.bypassSecurityTrustResourceUrl( '/assets/icons/text-size.svg' ) );
  matIconRegistry.addSvgIcon( 'document-content', domSanitizer.bypassSecurityTrustResourceUrl( '/assets/icons/document-content.svg' ) );
  matIconRegistry.addSvgIcon( 'edit-2', domSanitizer.bypassSecurityTrustResourceUrl( '/assets/icons/edit-2.svg' ) );
  matIconRegistry.addSvgIcon( 'edit', domSanitizer.bypassSecurityTrustResourceUrl( '/assets/icons/edit.svg' ) );
  matIconRegistry.addSvgIcon( 'edit', domSanitizer.bypassSecurityTrustResourceUrl( '/assets/icons/edit.svg' ) );
  matIconRegistry.addSvgIcon( 'upload-cloud', domSanitizer.bypassSecurityTrustResourceUrl( '/assets/icons/upload-cloud.svg' ) );
  matIconRegistry.addSvgIcon( 'rotate-left', domSanitizer.bypassSecurityTrustResourceUrl( '/assets/icons/rotate-left.svg' ) );
  matIconRegistry.addSvgIcon( 'rotate-right', domSanitizer.bypassSecurityTrustResourceUrl( '/assets/icons/rotate-right.svg' ) );
  matIconRegistry.addSvgIcon( 'close', domSanitizer.bypassSecurityTrustResourceUrl( '/assets/icons/close.svg' ) );
  matIconRegistry.addSvgIcon( 'cv-file-document', domSanitizer.bypassSecurityTrustResourceUrl( '/assets/icons/cv-file-document.svg' ) );
  matIconRegistry.addSvgIcon( 'document-file-clip', domSanitizer.bypassSecurityTrustResourceUrl( '/assets/icons/document-file-clip.svg' ) );
  matIconRegistry.addSvgIcon( 'painting-palette', domSanitizer.bypassSecurityTrustResourceUrl( '/assets/icons/painting-palette.svg' ) );
  matIconRegistry.addSvgIcon( 'pencil', domSanitizer.bypassSecurityTrustResourceUrl( '/assets/icons/pencil.svg' ) );
  matIconRegistry.addSvgIcon( 'document-file-plus-minus', domSanitizer.bypassSecurityTrustResourceUrl( '/assets/icons/document-file-plus-minus.svg' ) );
  matIconRegistry.addSvgIcon( 'essential-spelling', domSanitizer.bypassSecurityTrustResourceUrl( '/assets/icons/essential-spelling.svg' ) );
  matIconRegistry.addSvgIcon( 'language-translate', domSanitizer.bypassSecurityTrustResourceUrl( '/assets/icons/language-translate.svg' ) );
  matIconRegistry.addSvgIcon( 'sort-ascending', domSanitizer.bypassSecurityTrustResourceUrl( '/assets/icons/sort-ascending.svg' ) );
  matIconRegistry.addSvgIcon( 'user-document', domSanitizer.bypassSecurityTrustResourceUrl( '/assets/icons/user-document.svg' ) );
  matIconRegistry.addSvgIcon( 'user-money', domSanitizer.bypassSecurityTrustResourceUrl( '/assets/icons/user-money.svg' ) );
  matIconRegistry.addSvgIcon( 'link-1', domSanitizer.bypassSecurityTrustResourceUrl( '/assets/icons/link-1.svg' ) );
  matIconRegistry.addSvgIcon( 'download-cloud', domSanitizer.bypassSecurityTrustResourceUrl( '/assets/icons/download-cloud.svg' ) );
  matIconRegistry.addSvgIcon( 'suitcase', domSanitizer.bypassSecurityTrustResourceUrl( '/assets/icons/suitcase.svg' ) );
  matIconRegistry.addSvgIcon( 'language', domSanitizer.bypassSecurityTrustResourceUrl( '/assets/icons/language.svg' ) );
  matIconRegistry.addSvgIcon( 'loader', domSanitizer.bypassSecurityTrustResourceUrl( '/assets/icons/loader.svg' ) );
  matIconRegistry.addSvgIcon( 'progress-graduate', domSanitizer.bypassSecurityTrustResourceUrl( '/assets/icons/progress-graduate.svg' ) );
  matIconRegistry.addSvgIcon( 'sidebar', domSanitizer.bypassSecurityTrustResourceUrl( '/assets/icons/sidebar.svg' ) );
  matIconRegistry.addSvgIcon( 'pen-ruler', domSanitizer.bypassSecurityTrustResourceUrl( '/assets/icons/pen-ruler.svg' ) );
  matIconRegistry.addSvgIcon( 'volleyball', domSanitizer.bypassSecurityTrustResourceUrl( '/assets/icons/volleyball.svg' ) );
  matIconRegistry.addSvgIcon( 'user-message', domSanitizer.bypassSecurityTrustResourceUrl( '/assets/icons/user-message.svg' ) );
  matIconRegistry.addSvgIcon( 'graduate-person', domSanitizer.bypassSecurityTrustResourceUrl( '/assets/icons/graduate-person.svg' ) );
  matIconRegistry.addSvgIcon( 'calendar-checklist', domSanitizer.bypassSecurityTrustResourceUrl( '/assets/icons/calendar-checklist.svg' ) );
  matIconRegistry.addSvgIcon( 'duplicate-file', domSanitizer.bypassSecurityTrustResourceUrl( '/assets/icons/duplicate-file.svg' ) );
  matIconRegistry.addSvgIcon( 'upload-cloud', domSanitizer.bypassSecurityTrustResourceUrl( '/assets/icons/upload-cloud.svg' ) );
  matIconRegistry.addSvgIcon( 'rotate-left', domSanitizer.bypassSecurityTrustResourceUrl( '/assets/icons/rotate-left.svg' ) );
  matIconRegistry.addSvgIcon( 'rotate-right', domSanitizer.bypassSecurityTrustResourceUrl( '/assets/icons/rotate-right.svg' ) );
  matIconRegistry.addSvgIcon( 'close', domSanitizer.bypassSecurityTrustResourceUrl( '/assets/icons/close.svg' ) );
  matIconRegistry.addSvgIcon( 'plus-circle', domSanitizer.bypassSecurityTrustResourceUrl( '/assets/icons/plus-circle.svg' ) );
  matIconRegistry.addSvgIcon( 'star-filled', domSanitizer.bypassSecurityTrustResourceUrl( '/assets/icons/star-filled.svg' ) );
  matIconRegistry.addSvgIcon( 'unibase-send', domSanitizer.bypassSecurityTrustResourceUrl( '/assets/icons/unibase-send.svg' ) );

  // PDF template Icons
  matIconRegistry.addSvgIcon( 'elegant-mail', domSanitizer.bypassSecurityTrustResourceUrl( '/assets/pdf/elegant/envelope-open.svg' ) );
  matIconRegistry.addSvgIcon( 'elegant-phone', domSanitizer.bypassSecurityTrustResourceUrl( '/assets/pdf/elegant/phone.svg' ) );
}
