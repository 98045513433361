import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LanguageComponent } from './components/language/language.component';

const routes: Routes = [
  {
    path: ':languageCode',
    component: LanguageComponent,
    children: [
      {
        path: 'cv-generator',
        loadChildren: () =>
          import('./cv-generator/cv-generator.module').then(
            (m) => m.CvGeneratorModule,
          ),
      },
      {
        path: 'cv/:viewToken',
        loadChildren: () =>
          import('./view-cv/view-cv.module').then((m) => m.ViewCvModule),
      },
      {
        path: 'load-cv/:cvUuid',
        loadChildren: () =>
          import('./load-cv/load-cv.module').then((m) => m.LoadCvModule),
      },
      {
        path: 'apply',
        loadChildren: () =>
          import('./apply/apply.module').then((m) => m.ApplyModule),
      },
      {
        path: '**',
        loadChildren: () =>
          import('./home/home.module').then((m) => m.HomeModule),
      },
    ],
  },
  {
    path: '',
    loadChildren: () => import('./home/home.module').then((m) => m.HomeModule),
  },
  // { path: '**', redirectTo: '/de', pathMatch: 'full' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledBlocking',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
