import {
  Component,
  Inject,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
} from '@angular/core';
import {
  DEFAULT_LANGUAGE,
  LanguageService,
  SupportedLanguage,
  supportedLanguages,
} from './services/language/language.service';

import { isPlatformBrowser, Location } from '@angular/common';
import { QuillService } from 'ngx-quill';
import { quillUndoIcon } from './modules/custom-quill/quill-undo-icon';
import { quillRedoIcon } from './modules/custom-quill/quill-redo-icon';
import { first, fromEvent, map, merge, of, Subscription } from 'rxjs';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { registerIcons } from './helpers/registerIcons';
import { NavigationEnd, Router } from '@angular/router';
import { SeoService } from './services/seo/seo.service';
import { JsonLDService } from './services/json-l-d/json-l-d.service';
import { environment } from '../environments/environment';
import { getCanonicalUrl } from './helpers/getCanonicalUrl';
import { filter } from 'rxjs/operators';
import { I18NextPipe } from 'angular-i18next';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'lebenslauf-generator';
  isBrowser: boolean;
  networkOffline = false;
  networkMessage?: string;
  networkStatus$: Subscription = Subscription.EMPTY;

  constructor(
    private location: Location,
    private languageService: LanguageService,
    private quillService: QuillService,
    @Inject(PLATFORM_ID) private platformId: Record<string, unknown>,
    matIconRegistry: MatIconRegistry,
    domSanitizer: DomSanitizer,
    private router: Router,
    private seoService: SeoService,
    private jsonLDService: JsonLDService,
    private i18nPipe: I18NextPipe,
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
    this.configQuillModules();
    registerIcons(matIconRegistry, domSanitizer);
    this.subscribeNavigationEnd();
  }

  ngOnInit(): void {
    const lang = this.location.path().slice(1, 3);
    if (lang && supportedLanguages.includes(lang as SupportedLanguage)) {
      this.languageService.initLanguage(lang as SupportedLanguage);
    } else {
      this.languageService.initLanguage(DEFAULT_LANGUAGE);
    }
    this.checkNetworkStatus();
  }

  subscribeNavigationEnd(): void {
    const noIndexRoutes = ['cv'];
    this.router.events
      .pipe(
        filter(
          (event): event is NavigationEnd => event instanceof NavigationEnd,
        ),
      )
      .subscribe((event) => {
        const urlParts = event.url.slice(1).split('/');
        if (
          (urlParts[0] !== undefined && noIndexRoutes.includes(urlParts[0])) ||
          (urlParts[1] !== undefined && noIndexRoutes.includes(urlParts[1]))
        ) {
          this.seoService.setNoIndex();
        } else {
          this.seoService.setIndex();
          const canonicalUrl = environment.hostUrl + getCanonicalUrl(event.url);
          this.seoService.updateCanonicalUrl(canonicalUrl);
          this.jsonLDService.resolveNavigationEnd(event);
        }
      });
  }

  configQuillModules(): void {
    if (!this.isBrowser) {
      return;
    }
    this.quillService
      .getQuill()
      .pipe(first())
      .subscribe((Quill) => {
        this.configureQuill(Quill);

        const icons = Quill.import('ui/icons');
        icons['undo'] = quillUndoIcon;
        icons['redo'] = quillRedoIcon;
      });
  }

  /**
   * Changes behaviour in bubble theme to open bubble on a single click
   */
  configureQuill(Quill: any) {
    Quill.debug('error');

    // Override bubble theme to open bubble on a single click
    const BubbleTheme = Quill.import('themes/bubble');

    class ExtendBubbleTheme extends BubbleTheme {
      constructor(quill: any, options: any) {
        super(quill, options);

        quill.on('selection-change', (range: string) => {
          if (range) {
            quill.theme.tooltip.show();
            quill.theme.tooltip.position(quill.getBounds(range));
          }
        });
      }
    }

    Quill.register('themes/bubble', ExtendBubbleTheme);
  }

  checkNetworkStatus() {
    if (!this.isBrowser) {
      return;
    }
    this.networkOffline = !navigator.onLine;
    this.networkStatus$ = merge(
      of(null),
      fromEvent(window, 'online'),
      fromEvent(window, 'offline'),
    )
      .pipe(map(() => !navigator.onLine))
      .subscribe((isOffline) => {
        this.networkOffline = isOffline;
        if (isOffline) {
          this.networkMessage = this.i18nPipe.transform('networkIsOffline');
        }
      });
  }

  ngOnDestroy(): void {
    this.networkStatus$.unsubscribe();
  }
}
