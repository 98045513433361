import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'app-uni-icon',
  templateUrl: './uni-icon.component.html',
  styleUrls: ['./uni-icon.component.scss'],
})
export class UniIconComponent {
  @Input() icon: string | undefined = 'icon-placeholder';
  @Input() rotate = 0;

  @HostBinding('class.icon-fab')
  @Input()
  isFab = false;

  @Input() size:
    | 'xxs'
    | 'xs'
    | 's'
    | 'normal'
    | 'l'
    | 'xl'
    | 'xxl'
    | 'dynamic' = 'normal'; // dynamic means no width gets defined by the component

  @HostBinding('class') get sizeClass() {
    return `size-${this.size}`;
  }

  @HostBinding('class.decorated')
  @Input()
  decorated = false;

  @HostBinding('class.decoration-light')
  @Input()
  decorationLight = false;

  constructor() {}
}
