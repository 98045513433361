import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { I18NextService } from 'angular-i18next';
import { DOCUMENT } from '@angular/common';

import { de, enUS, frCH, it } from 'date-fns/locale';

export const DEFAULT_LANGUAGE: SupportedLanguage = 'de';

export type SupportedLanguage = 'de' | 'fr' | 'en' | 'it';

export const supportedLanguages: SupportedLanguage[] = ['de', 'fr', 'en'];

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  defaultLanguage = DEFAULT_LANGUAGE;
  supportedLanguages = supportedLanguages;
  activeLanguage: SupportedLanguage = this.defaultLanguage;
  activeLanguageSubject = new BehaviorSubject(this.defaultLanguage);
  activeLanguageObservable$ = this.activeLanguageSubject.asObservable();

  constructor(
    private i18NextService: I18NextService,
    private router: Router,
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId: any,
  ) {}

  initLanguage(lang: SupportedLanguage): void {
    this.activeLanguage = lang;
    this.activeLanguageSubject.next(this.activeLanguage);
    this.document.documentElement.lang = this.activeLanguage;
    this.i18NextService.changeLanguage(lang).then();
  }

  async changeLanguage(lang: string): Promise<void> {
    await this.navigateToLanguage(lang);
  }

  async navigateToLanguage(lang: string): Promise<void> {
    if (this.activeLanguage === lang) {
      return;
    }
    const regEx = new RegExp(`\/${this.activeLanguage}`, 'g');
    if (this.router.url === '/') {
      this.document.location.href = `/${lang}`;
    } else {
      this.document.location.href = this.router.url.replace(regEx, `/${lang}`);
    }
  }

  getDateFnsLocale(): Locale {
    switch (this.activeLanguage) {
      case 'de':
        return de;
      case 'fr':
        return frCH;
      case 'en':
        return enUS;
      case 'it':
        return it;
      default:
        return de;
    }
  }
}
