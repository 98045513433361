import { NgModule } from '@angular/core';
import { LoadingOverlayComponent } from './loading-overlay.component';
import { SharedDefaultModule } from '../shared-default/shared-default.module';

@NgModule({
  declarations: [LoadingOverlayComponent],
  exports: [LoadingOverlayComponent],
  imports: [SharedDefaultModule],
})
export class LoadingOverlayModule {}
