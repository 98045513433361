export const environment = {
  production: true,
  showDebugPdfDownloadButton: false,
  showAiCoverLetterButton: true,
  debugRectangles: false,
  loadSampleData: false,
  hostUrl: 'https://dev.lebenslauf.ch',
  cvGeneratorApiRestUrl: 'https://dev.api.lebenslauf.ch',
  cvGeneratorApiGQLUrl: 'https://dev.api.lebenslauf.ch/graphql',
  gqlApiXWsUrl: 'wss://dev.api.lebenslauf.ch/graphql',
};
