import { I18NEXT_SERVICE, ITranslationService } from 'angular-i18next';
import { APP_INITIALIZER, LOCALE_ID } from '@angular/core';

import cvGeneratorTranslationsEn from '../locales/cv-generator.en.json';
import cvGeneratorTranslationsDe from '../locales/cv-generator.de.json';
import cvGeneratorTranslationsIt from '../locales/cv-generator.it.json';
import cvGeneratorTranslationsFr from '../locales/cv-generator.fr.json';
import {
  DEFAULT_LANGUAGE,
  supportedLanguages,
} from './services/language/language.service';

export function getAppInitFactory(
  defaultLanguage: string = DEFAULT_LANGUAGE,
): (arg0: ITranslationService) => any {
  return (i18next: ITranslationService): any => {
    return () =>
      i18next.init({
        supportedLngs: supportedLanguages,
        fallbackLng: defaultLanguage,
        debug: false,
        returnEmptyString: false,
        resources: {
          en: {
            translation: { ...cvGeneratorTranslationsEn },
          },
          de: {
            translation: { ...cvGeneratorTranslationsDe },
          },
          fr: {
            translation: { ...cvGeneratorTranslationsFr },
          },
          it: {
            translation: { ...cvGeneratorTranslationsIt },
          },
        },
        ns: ['translation', 'validation', 'error'],
      });
  };
}

export function localeIdFactory(i18next: ITranslationService): string {
  return i18next.language;
}

export const I18N_PROVIDERS = [
  {
    provide: APP_INITIALIZER,
    useFactory: getAppInitFactory(),
    deps: [I18NEXT_SERVICE],
    multi: true,
  },
  {
    provide: LOCALE_ID,
    deps: [I18NEXT_SERVICE],
    useFactory: localeIdFactory,
  },
];

export const I18N_TESTING_PROVIDERS = [
  {
    provide: APP_INITIALIZER,
    useFactory: getAppInitFactory('en'),
    deps: [I18NEXT_SERVICE],
    multi: true,
  },
  {
    provide: LOCALE_ID,
    deps: [I18NEXT_SERVICE],
    useFactory: localeIdFactory,
  },
];
