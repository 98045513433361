import { NgModule } from '@angular/core';
import { QuillModule } from 'ngx-quill';

@NgModule({
  declarations: [],
  imports: [
    QuillModule.forRoot({
      formats: ['bold', 'italic', 'underline', 'strike', 'list', 'link'],
      modules: {
        toolbar: {
          container: [
            ['bold', 'italic'],
            [{ list: 'bullet' }, { list: 'ordered' }],
            ['undo'],
            ['redo'],
          ],
          handlers: {
            undo: function () {
              // disable undo for initial content (deleting everything in the editor with undo)
              const canUndo = this['quill'].history.stack.undo.length > 1;
              if (canUndo) {
                this['quill'].history.undo();
              }
            },
            redo: function () {
              this['quill'].history.redo();
            },
          },
        },
        // prevent quill inline styles
        clipboard: {
          matchVisual: false,
        },
      },
    }),
  ],
})
export class CustomQuillModule {}
