import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { I18NextModule } from 'angular-i18next';
import { I18N_PROVIDERS } from './i18n-providers';
import { LanguageComponent } from './components/language/language.component';
import {
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { SharedDefaultModule } from './modules/shared-default/shared-default.module';
import { CustomQuillModule } from './modules/custom-quill/custom-quill.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule } from '@angular/forms';
import { CookieHintModule } from './modules/cookie-hint/cookie-hint.module';
import { LoadingOverlayModule } from './modules/loading-overlay/loading-overlay.module';

@NgModule({
  declarations: [AppComponent, LanguageComponent],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    I18NextModule.forRoot(),
    SharedDefaultModule,
    CustomQuillModule,
    ReactiveFormsModule,
    CookieHintModule,
    LoadingOverlayModule,
  ],
  providers: [I18N_PROVIDERS, provideHttpClient(withInterceptorsFromDi())],
})
export class AppModule {
  constructor() {}
}
